<template>
  <div class="full-container">
    <div class="setting-container">
      <div class="flex nowrap setting-item">
        <p class="setting-tips">IMEI：</p>
        <div class="flex-no-wrap fields-item">
          <input
              type="number"
              class="fields-input"
              v-model="imei"
              @input="checkLength('imei')"
          />
        </div>
      </div>

      <div class="flex nowrap setting-item">
        <p class="setting-tips">锁号：</p>
        <div class="flex-no-wrap fields-item">
          <input
              type="number"
              class="fields-input"
              v-model="lockNo"
              @input="checkLength('lockNo')"
          />
        </div>
      </div>


      <md-field>
        <md-field-item
            title="服务："
            :content="selectorValue"
            @click="showSelector"
            arrow
            solid
        />
      </md-field>
      <md-selector
          v-model="isSelectorShow"
          :default-value="serviceId"
          :data="serviceData"
          max-height="220px"
          title="请选择服务"
          large-radius
          @choose="onSelectorChoose"
      ></md-selector>

      <md-button
          type="primary"
          size="small"
          class="setting-one-add-btn"
          @click="confirmBegin">维护</md-button>
    </div>
    <div class="version">版本：{{ $version }}</div>
  </div>
</template>

<script>
import axios from "axios";
import apiConfig from "../../assets/config/api.config";
import {Dialog, Toast} from "mand-mobile";
import Scan from "../../components/Scan";

export default {
  name: "AddLockRecord",
  components: {
    Scan,
  },
  data() {
    return {
      lockNo: '',
      imei: '',
      isSelectorShow: false,
      selectorValue: "畅停",
      serviceId: "1",
      serviceData: [
        {value: "0", text: '无'}
      ],
      isErScan: false,
    }
  },
  created() {
    document.title = '锁ID维护';
    this.getServiceList();
    window.onScanSuccess = (...arg)=> this.onScanSuccess(...arg); // 果然需要进行挂载！！！
  },
  methods:{
    confirmBegin() {
      if (this.lockNo.length === 10 && this.imei.length === 15) {
        Dialog.confirm({
          title: "确认",
          content: "是否要开始锁ID？",
          confirmText: "开始",
          onConfirm: () => {
            this.addLockRecord();
          },
        });
      }else {
        Toast.info("请输入正确的锁号或IEMI");
      }
    },
    // 向永久化服务增加一条记录
    addLockRecord() {
      if(!this.serviceId) {
        Toast.info('请选择服务类型');
      }
      let params = {
        staffId: '88888',
        imei: this.imei,
        lockNo: this.lockNo,
        serviceId: parseInt(this.serviceId),
        statusCode: 200  // 表示只是插入记录，并没有状态显示
      }
      axios.post(apiConfig.saveSetting, params).then(res => {
        if(res.data) {
          Toast.info('配置成功')
        }
      }).catch(err => {
        if(err && err.response) {
          if(err.response.status === 401) {
            this.autoLogin(this.getServiceList);
          }
        }
      })
    },
    // 使用安卓原生方法
    useAndroidScan(){
      javascript:scanner.startScan('onScanSuccess')
    },
    // 控制config Modal的显示
    controlScanModal(bool, type) {
      if (type === "er") {
        this.isErScan = bool;
      } else if (type === "bar") {
        this.isBarScan = bool;
      }
    },
    // 从二维码中过滤出锁号
    filterLock(string, params = "lock") {
      if (string) {
        let search = string.split("?")[1];
        return search.replace(params + "=", "");
      }
    },
    getScanLockCode(code) {
      this.isErScan = false;
      this.lockNo = this.filterLock(code);
    },
    // check 锁号或是iemi的长度
    checkLength(type) {
      if (type === "imei") {
        this.imei = this.imei.slice(0, 15);
        if(this.imei.length === 15) {
          // 默认回填一个锁号
          this.lockNo = '99' + this.imei.slice(2, 4) + this.imei.slice(8, 14)
        }
        if (this.imei.length !== 15) {
          Toast.info("IMEI号为15位数字，请校验并重新输入");
        }
      } else if(type === "lockNo"){
        this.lockNo = this.lockNo.slice(0, 10);
        if (this.lockNo.length !== 10) {
          Toast.info("锁号为10位数字，请校验并重新输入");
        }
      }
    },
    onSelectorChoose(option) {
      this.serviceId = option.value;
      this.selectorValue = option.text;
    },
    showSelector() {
      this.isSelectorShow = true;
    },
    // 巡检员的老大自动登陆
    autoLogin(callback) {
      let params = new FormData();
      params.append('username', 'ctp_manager_01');
      params.append('password', 'abc12345678');
      axios.post(apiConfig.login, params, this.$headers).then(res => {
        if(res.data.code === 200) {
          localStorage.setItem('user', 'ctp_manager_01');
          callback();
        }else {
          Toast.info(res.data.msg)
        }
      })
    },
    // 获取服务列表
    getServiceList() {
      axios.get(apiConfig.getServiceList).then(res => {
        if(res.data) {
          res.data.map(d => {
            this.serviceData.push({
              value: d.Id,
              text: d.Name.split('_')[0]
            })
          })
        }
      })
      .catch(err => {
        if(err) {
          this.autoLogin(this.getServiceList);
          if(err.response && err.response.status === 401) {
            this.autoLogin(this.getServiceList);
          }
        }

      })
    },
  }
}
</script>

<style lang="less" src="./../Setting/setting.less" scoped></style>
<style lang="less" scoped>
  .fields-item {
    width: 100%;
  }
</style>
