import { render, staticRenderFns } from "./addLockRecord.vue?vue&type=template&id=142b845c&scoped=true&"
import script from "./addLockRecord.vue?vue&type=script&lang=js&"
export * from "./addLockRecord.vue?vue&type=script&lang=js&"
import style0 from "./../Setting/setting.less?vue&type=style&index=0&id=142b845c&prod&lang=less&scoped=true&"
import style1 from "./addLockRecord.vue?vue&type=style&index=1&id=142b845c&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "142b845c",
  null
  
)

export default component.exports